<template>
  <div>
    <v-card v-if="error">
      <v-sheet dark color="error" class="pa-3">
        <div id="card-errors" role="alert">エラー。</div>
      </v-sheet>
    </v-card>
    <UpdateLabel
      v-else
      :currentLabelProp="label"
      :campaignId="campaignId"
      :saveLabel="addLabelToCampaignId"
    />
  </div>
</template>

<script>
import UpdateLabel from '@/components/update-label'
import { getCampaignIdLabel } from '@/helpers/update-label'
import { addLabelToCampaignId } from '@/helpers/firebase-functions'
import { createNamespacedHelpers } from 'vuex'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
const { mapState: authState } = createNamespacedHelpers('auth')

export default {
  components: {
    UpdateLabel
  },
  props: {
    campaignId: {
      type: String
    }
  },
  data () {
    return {
      loading: true,
      label: null,
      error: false
    }
  },
  computed: {
    ...authState(['uid'])
  },
  watch: {
    uid: {
      immediate: true,
      handler (newUid) {
        if (newUid) {
          this.loadCampaignLabelData(newUid)
        }
      }
    }
  },
  methods: {
    async addLabelToCampaignId ({ label }) {
      await addLabelToCampaignId({ label, campaignId: this.campaignId })
      return this.loadCampaignLabelData(this.uid)
    },
    async loadCampaignLabelData (uid) {
      try {
        this.label = await getCampaignIdLabel({ uid, campaignId: this.campaignId })
      } catch (err) {
        this.error = true
        console.error('loadCampaignLabelData error', err) // eslint-disable-line no-console
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_UPDATE_LABEL)
  }
}
</script>

<style>
</style>
