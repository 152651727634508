import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'

// export async function getCampaignIdLabel ({ uid, campaignId }) {
//   return firestore.collection(COLLECTIONS_ENUM.OWNERS).doc(uid).collection(COLLECTIONS_ENUM.CAMPAIGN_ID_LABEL_MAPPINGS).doc(campaignId).get().then(doc => doc.get('label'))
// }

export async function getCampaignIdLabel ({ uid, campaignId }) {
  const scenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
  return firestore
    .collection(COLLECTIONS_ENUM.OWNERS)
    .doc(uid)
    .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
    .doc(scenarioId)
    .collection(COLLECTIONS_ENUM.QR_CODES)
    .doc(campaignId)
    .get()
    .then(doc => doc.get('label'))
}
