<template>
  <v-layout class="mt-3" align-center justify-center>
    <v-snackbar v-model="snackbar" :timeout="2000" color="success" top>
      保存しました
      <v-btn color="white" icon @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-flex xs12 sm8 md4>
      <v-card v-if="!isCancelled">
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">QRコード名の更新</h3>
          </div>
        </v-card-title>
        <v-card-text>このQRコードの名前を入力してください。</v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>ID</v-list-item-title>
              <v-list-item-subtitle>{{ campaignId }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>現状の名前</v-list-item-title>
              <v-list-item-subtitle>{{ currentLabel }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row class="mx-1">
          <v-col cols="12">
            <v-text-field
              v-model="newLabel"
              :disabled="processing"
              label="新しい名前"
              placeholder="新しい名前をここに入力してください。"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="onSaveLabel"
            :disabled="!hasInput"
            :loading="processing"
            color="amber lighten-2"
            >保存</v-btn
          >
          <v-btn
            outlined
            color="amber lighten-2"
            :disabled="processing"
            @click="isCancelled = true"
            >キャンセル</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title primary-title>
          <div>操作を取り消しました</div>
        </v-card-title>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  props: {
    campaignId: {
      type: String,
      required: true
    },
    saveLabel: {
      type: Function,
      required: true
    },
    currentLabelProp: {
      type: String
    }
  },
  data () {
    return {
      isCancelled: false,
      processingSave: false,
      snackbar: false,
      newLabel: ''
    }
  },
  computed: {
    processing () {
      return this.processingSave
    },
    hasInput () {
      return (this.newLabel || '').trim() !== ''
    },
    currentLabel () {
      return this.currentLabelProp || 'なし'
    }
  },
  methods: {
    async onSaveLabel () {
      try {
        if (this.hasInput) {
          this.processingSave = true
          await this.saveLabel({ label: this.newLabel })
          this.newLabel = ''
          this.snackbar = true
        }
      } finally {
        this.processingSave = false
      }
    }
  }
}
</script>

<style>
</style>
